<template>
	<div class="main" ref="main">
		<!-- 内容盒子 -->
		<div class="home pageItem">
			<div class="maintenance">{{ $t('chat.maintenance') }}</div>
		</div>
		
	</div>
</template>
<script>


export default {
	name: "AppMain",
	props: {
		index: {
			type: String,
			default: '1'
		},
	},

	data() {
		return {
			
		}
	},

	mounted() {
		
		
	},
	beforeDestroy() {
	
	},
	watch: {
		
	},
	computed: {
		
	},

	methods: {
		

		
	},

}
</script> 
<style scoped lang="less">


.text {
	font-family: 'Cormorant';
	font-size: 24px;
}

.main {
	width: 100vw;
	height: 100vh;
}


.maintenance {
	display: flex;
	justify-content: center;

	align-items: center;

	height: 100vh;
}


</style>
