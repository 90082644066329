// zh.js
export default {
  chat:{
    qingliodenglu:"事密达 · 轻聊登录",
    erweimashixiao:"二维码已失效",
    saomacg:"扫码成功",
    denglucg:"登录成功",
    appsaoma:"使用事密达APP扫码登录",
    guize1:"1.打开手机事密达APP",
    guize2:"2.点击【对话】右上角“+”的“扫一扫”，或选择【我的】—【事密达轻聊登录】点击“二维码扫描登录”",
    guize3:"3.扫描网页的二维码识别登录事密达轻聊",
    guize4:"4.如有遇到登录问题，也可以在事密达APP里向在线客服咨询",
    tuichubiaoti:"确定要退出事密达轻聊吗?",
    tuichudenglu:"是否确定退出当前账号登录",
    quxiao:"取消",
    queding:"确定",
    bukegenghuan:"轻聊版不可更换，可前往事密达APP内进行设置",
    jinyongzhong:"该群禁用中",
    yongjiudongjie:"该群永久冻结",
    dongjie:"冻结中 ",
    jiechu:"解除",
    yongjiujinyan:"该群永久禁言",
    jinyan:"禁言中 ",
    zhanghaojinyanz:"账号禁言中 ",
    quanyuanjinyan:"全员禁言中，仅群主管理员可发言",
    xxsbqunguanji:"消息发送失败，群管家限制该内容发送",
    buyunxushangc:"不允许上传该类型文件",
    qianwangappkan:"当前加密消息需前往事密达APP查看",
    tupianxx:"[图片消息]",
    shipinxx:"[视频消息]",
    yuyinxx:"[语音消息]",
    qitaxx:"[当前加密消息需前往事密达APP查看]",
    shangwu:"上午",
    xiawu:"下午",
    yue:"月",
    ri:"日",
    xiaoxibuweikong:"发送消息不能为空！",
    minganxiaoxi:"消息发送失败，含有敏感信息，请重新输入",
    guanfangweigui:"官方检测违规，本群永久封禁",
    xiaoxifasongshibai:"消息发送失败",
    shurumingcheng:"请输入好友、群聊名称",
    haoyou:"好友",
    gengduo:"更多",
    qunliao:"群聊",
    zanwujieguo:"暂无搜索结果~",
    jiazaizhong:"加载中：",
    yourenaw:"[有人@我]",
    qungonggao:"[群公告]",
    qunlaba:"[群喇叭]",
    tongguojiami:"聊天信息通过端对端加密处理",
    qianwangapp:"当前加密消息需前往事密达APP查看",
    youxinxiaoxi:"有新消息",
    huanhang:"按下Ctrl+Enter换行",
    fasong:"发送",
    qungonggao:"群公告",
    laiziqunlab:"来自群喇叭",
    lingqule:"领取了",
    dekaquan:"的卡券",
    youanide:"有@你消息",
    jiazaigengduo:"加载更多",
    fasongxiaoxi:"发送消息",
    gexingqianming:"个性签名",
    logout:"退出登录",
    shanchu:"删除",
    
    maintenance : '功能维护中...',
    
   
  },
 
};