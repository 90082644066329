// zn.js
export default {
  chat:{
    qingliodenglu:"Shimida-Light Chat Login",
    erweimashixiao:"QR code has expired",
    saomacg:"Scan code successfully",
    denglucg:"Login succeeded",
    appsaoma:"Use the Shimida app to scan and log in",
    guize1:"1.Open the mobile Shimida app",
    guize2:`2.Click "Scan" under "+" in the upper right corner of [Dialog], Or select [My] - [Shimida-Light Chat Login] click "QR code scan login"`,
    guize3:"3.Scan the QR code of the webpage to identify the login of the Shimida light chat",
    guize4:"4.If you encounter login issues, you can also consult online customer service in the Shimida app",
    tuichubiaoti:"Are you sure you want to quit the Shimida light chat?",
    tuichudenglu:"Are you sure to log out of the current account?",
    quxiao:"cancel",
    queding:"sure",
    bukegenghuan:"The light chat version is not replaceable and can be set up in the Shimida app",
    jinyongzhong:"This group chat is currently disabled",
    yongjiudongjie:"This group chat is permanently frozen",
    dongjie:"Frozen ",
    jiechu:"Unfreeze",
    yongjiujinyan:"This group chat is permanently banned from speaking",
    jinyan:"Speaking not allowed ",
    zhanghaojinyanz:"Account prohibited from speaking ",
    quanyuanjinyan:"All members are prohibited from speaking, and only group manager can speak",
    xxsbqunguanji:"Message sending failed. The group manager has restricted the content from being sent",
    buyunxushangc:"Uploading this type of file is not allowed",
    qianwangappkan:"The current encrypted message needs to be viewed by the Shimida APP",
    tupianxx:"[Picture message]",
    shipinxx:"[Video messages]",
    yuyinxx:"[Voice message]",
    qitaxx:"[The current encrypted message needs to be viewed by the Shimida APP]",
    shangwu:"Morning",
    xiawu:"Afternoon",
    yue:"Month",
    ri:"Day",
    xiaoxibuweikong:"Sending message cannot be empty!",
    minganxiaoxi:"The message failed to be sent. It contains sensitive information. Please reenter",
    guanfangweigui:"Official detection violation, permanent ban on this group chat",
    xiaoxifasongshibai:"The message failed to be sent",
    shurumingcheng:"Please enter a friend and group chat name",
    haoyou:"friend",
    gengduo:"more",
    qunliao:"group chat",
    zanwujieguo:"No search results currently available~",
    jiazaizhong:"Loading：",
    yourenaw:"[You were mentioned]",
    qungonggao:"[Group Announcements]",
    qunlaba:"[Group horn]",
    tongguojiami:"Chat messages are encrypted end-to-end",
    qianwangapp:"The current encrypted message needs to be viewed by the Shimida APP",
    youxinxiaoxi:"There's new information",
    huanhang:"Press Ctrl+Enter to wrap",
    fasong:"Send",
    qungonggao:"Group Announcements",
    laiziqunlab:"From the Group horn",
    lingqule:"Received",
    dekaquan:"Card vouchers",
    youanide:"You were mentioned",
    jiazaigengduo:"Load more",
    fasongxiaoxi:"Sending messages",
    gexingqianming:"Signature of personality",
    logout:"Log out of login",
    shanchu:"Delete",
    
    maintenance : 'Under maintenance...',
   
  },
 
};